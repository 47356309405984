
  import t0 from "./theme-client";
  import t1 from "./theme.asset-1.js";
import t2 from "./theme.asset-2.js";
import t3 from "./theme.asset-3.js";
import t5 from "./theme.asset-5.js";
import t6 from "./theme.asset-6.js";
import t8 from "./theme.asset-8.js";
import t9 from "./theme.asset-9.js";
import t15 from "./theme.asset-15.js";
import t16 from "./theme.asset-16.js";
import t20 from "./theme.asset-20.js";
import t21 from "./theme.asset-21.js";
import t22 from "./theme.asset-22.js";
import t23 from "./theme.asset-23.js";
import t25 from "./theme.asset-25.js";
import t26 from "./theme.asset-26.js";
import t28 from "./theme.asset-28.js";
import t30 from "./theme.asset-30.js";
import t31 from "./theme.asset-31.js";
import t32 from "./theme.asset-32.js";
import t33 from "./theme.asset-33.js";
import t34 from "./theme.asset-34.js";
import t35 from "./theme.asset-35.js";
import t36 from "./theme.asset-36.js";
import t37 from "./theme.asset-37.js";
import t38 from "./theme.asset-38.js";
import t39 from "./theme.asset-39.js";
import t40 from "./theme.asset-40.js";
import t41 from "./theme.asset-41.js";
import t42 from "./theme.asset-42.js";
import t43 from "./theme.asset-43.js";
import t44 from "./theme.asset-44.js";
import t45 from "./theme.asset-45.js";
import t46 from "./theme.asset-46.js";
import t47 from "./theme.asset-47.js";
import t48 from "./theme.asset-48.js";
import t49 from "./theme.asset-49.js";
import t50 from "./theme.asset-50.js";
import t51 from "./theme.asset-51.js";
import t52 from "./theme.asset-52.js";
import t53 from "./theme.asset-53.js";
import t54 from "./theme.asset-54.js";
import t55 from "./theme.asset-55.js";
import t56 from "./theme.asset-56.js";
import t57 from "./theme.asset-57.js";
import t58 from "./theme.asset-58.js";
import t59 from "./theme.asset-59.js";
import t60 from "./theme.asset-60.js";
import t61 from "./theme.asset-61.js";
import t62 from "./theme.asset-62.js";
import t63 from "./theme.asset-63.js";
import t64 from "./theme.asset-64.js";
import t65 from "./theme.asset-65.js";
import t66 from "./theme.asset-66.js";
import t67 from "./theme.asset-67.js";
import t68 from "./theme.asset-68.js";
import t69 from "./theme.asset-69.js";
import t70 from "./theme.asset-70.js";
import t71 from "./theme.asset-71.js";
import t72 from "./theme.asset-72.js";
import t75 from "./theme.asset-75.js";
import t76 from "./theme.asset-76.js";
import t77 from "./theme.asset-77.js";
import t78 from "./theme.asset-78.js";
import t79 from "./theme.asset-79.js";
import t80 from "./theme.asset-80.js";
import t83 from "./theme.asset-83.js";
import t84 from "./theme.asset-84.js";
import t85 from "./theme.asset-85.js";
import t86 from "./theme.asset-86.js";
import t87 from "./theme.asset-87.js";
import t88 from "./theme.asset-88.js";
import t89 from "./theme.asset-89.js";
import t90 from "./theme.asset-90.js";
import t91 from "./theme.asset-91.js";
import t92 from "./theme.asset-92.js";
import t93 from "./theme.asset-93.js";
import t94 from "./theme.asset-94.js";
import t95 from "./theme.asset-95.js";
import t96 from "./theme.asset-96.js";
import t97 from "./theme.asset-97.js";
import t98 from "./theme.asset-98.js";
import t99 from "./theme.asset-99.js";
import t100 from "./theme.asset-100.js";
import t101 from "./theme.asset-101.js";
import t102 from "./theme.asset-102.js";
import t103 from "./theme.asset-103.js";


  export const themes = {  1: t1,
 2: t2,
 3: t3,
 5: t5,
 6: t6,
 8: t8,
 9: t9,
 15: t15,
 16: t16,
 20: t20,
 21: t21,
 22: t22,
 23: t23,
 25: t25,
 26: t26,
 28: t28,
 30: t30,
 31: t31,
 32: t32,
 33: t33,
 34: t34,
 35: t35,
 36: t36,
 37: t37,
 38: t38,
 39: t39,
 40: t40,
 41: t41,
 42: t42,
 43: t43,
 44: t44,
 45: t45,
 46: t46,
 47: t47,
 48: t48,
 49: t49,
 50: t50,
 51: t51,
 52: t52,
 53: t53,
 54: t54,
 55: t55,
 56: t56,
 57: t57,
 58: t58,
 59: t59,
 60: t60,
 61: t61,
 62: t62,
 63: t63,
 64: t64,
 65: t65,
 66: t66,
 67: t67,
 68: t68,
 69: t69,
 70: t70,
 71: t71,
 72: t72,
 75: t75,
 76: t76,
 77: t77,
 78: t78,
 79: t79,
 80: t80,
 83: t83,
 84: t84,
 85: t85,
 86: t86,
 87: t87,
 88: t88,
 89: t89,
 90: t90,
 91: t91,
 92: t92,
 93: t93,
 94: t94,
 95: t95,
 96: t96,
 97: t97,
 98: t98,
 99: t99,
 100: t100,
 101: t101,
 102: t102,
 103: t103,
 }

  export const getThemeById = (id) => id && themes[id] ? themes[id] : t0

  export default t0;
