export default {
  API_URL: "https://api-cbreau-prod.findyourspot.io",
    API_SERVICE_URL: "https://services.bookacorner.io",
  CLOUDFRONT_URL: "https://files.findyourspot.io",
  CLIENT: "cbreau",
  CLIENT_NAME: "CBREAU",
  GOOGLE_MAPS_API_KEY: "AIzaSyAEMDPV_mgsfpSQB4Scj1YMX_z9sF0MIzQ",
  HOME_URL: "https://au.findyourspot.io",
  UPLOAD_URL: "https://saxtyc5ci8.execute-api.us-east-1.amazonaws.com/prod",
  LOGGLY_KEY: "b523b1f3-6dd4-4be4-a10f-017f039f4de5",
  PROFORMA_URL: "https://bac-mediafiles.s3.amazonaws.com/all/proforma-cbre.html",
  HOME_HEADER_IMG_URL: "https://files.findyourspot.io/all/media/Fotos+de+portada+para+HOME/Foto+Centro+1.jpg",
  HOME_COMMENT_TEXT: "LAS ACCIONES TEMPORALES DE BRANDING EN CBRE SON TODO UN ÉXITO, YA QUE SUS LOCALES Y SU PÚBLICO SON ÚNICOS EN ESPAÑA",
  HOME_COMMENT_AUTHOR: "Nike Director",
  HOME_COMMENT_LINK: "https://www.cbre.es/en/services/industries-and-specialties/retail",
  HOME_COMMENT_IMAGE: "https://www.cbre.es/-/media/cbre/countryspain/images/oficinas/oficina_barcelona.png",
  LOGO_LIGHT: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-light.png",
  LOGO_DARK: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-dark.png",
  ADMIN_DEFAULT_LOCALE: "en-cbreau",
  TENANT_DEFAULT_LOCALE: "en-cbreau",
  HELP: "",
  TERMS_AND_CONDITIONS: "https://bookacorner.com/condiciones-generales",
  PRIVACY_POLICY: "https://files.bookacorner.net/all/media/Privacy Policy/CBRE Privacy Policy.pdf",
  SOCIAL_LINKS: {"facebook":"https://www.facebook.com/CBREResi/","twitter":"https://twitter.com/cbreAustralia?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor","instagram":"https://www.instagram.com/cbre_aunz/?hl=es"},
  ADVERTISING_IMG: "https://files.findyourspot.io/all/media/Imagen+Advertising.jpeg",
  REVERB_APP_KEY: "QyPT5v8cpxaa2x2d4e05Vq7x",
  REVERB_HOST: "api-cbreau-prod.findyourspot.io",
  REVERB_AUTH_ENDPOINT: "https://api-cbreau-prod.findyourspot.io/api/broadcasting/auth",
  REVERB_PORT: 6001,
  FONT_API_URL: "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap",
  INDEXABLE: "true",
  LOCALES_SUPPORTED_ARRAY: ["es","en","fr","pt","es-cbreau","en-cbreau","pt-cbreau","fr-cbreau"],
  MAPBOX_API_KEY: "pk.eyJ1IjoibWF4aW1pbGlhbm9iYWMiLCJhIjoiY2wxd2U5YXFrMGNzajNjbzE3MmZrNXljeiJ9.s4gzv9rbU479joxwhHVJkA",
  HOME_MAP_STYLE: "mapbox://styles/maximilianobac/cl27mkmhx002614ppe2ubwzq4",
  HOME_MAP_BOUNDS: [[112.62118121812236,-39.54617577107478],[153.2439249473218,-9.191036709104273]],
  MAPBOX_MARKER: "https://files.findyourspot.io/all/markers/mapbox-icon-CBRE.png",
    PDF_WORKER: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.min.js",
  USER_MANUAL: "",
  COGNITO_URL: "https://bac-cbreau-prod.auth.ap-southeast-2.amazoncognito.com/login?client_id=3gi4pj10oabeh5iqlfomaisfth&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile",
}
